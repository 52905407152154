import Experience from '../Experience'
// import Scroll from '../Utils/Scroll'
import Sections from '../UI/Sections'
import Menu from '../UI/Menu'
import { gsap, Power2 } from 'gsap'

export default class UI {

    overlayButton = document.querySelector('.overlay-button');
    contactButton = document.querySelector('#contact-btn');
    toggleButton = document.querySelector('.toggle-button');
    sourceButtons = document.querySelectorAll('.btn-sourse');
    socialButtons = document.querySelectorAll('.social-menu li');
    swiperButtonNext = document.querySelector('.swiper-button-next');
    swiperButtonPrev = document.querySelector('.swiper-button-prev');
    
    constructor() {
        this.experience = new Experience();
        this.resources = this.experience.resources
        this.world = this.experience.world
        this.sizes = this.experience.sizes
        this.theme = this.experience.theme

        // Wait for resources
        this.resources.on('ready', () => {
            this.sections = new Sections()
            this.menu = new Menu()

            this.person = this.experience.world.person
            this.checkType();
        })

        this.sizes.on('resize', (device) => {
            this.device = device;
            this.changeModelPositionMobile(this.device);
        })

        this.animateCursor();
    }

    changeModelPositionMobile(device) {
        console.log(device);
        if (device === 'mobile') {
            gsap.to(this.person.model.position, {
                x: 1,
                // y: waypoint.position.y,
                // z: waypoint.position.z,
                duration: 1,
                ease: Power2.easeInOut
            })
            gsap.to(this.person.model.scale, {
                x: 3,
                y: 3,
                z: 3,
                duration: 1,
                ease: Power2.easeInOut
            })
        } else {
            gsap.to(this.person.model.position, {
                x: 3.3,
                // y: waypoint.position.y,
                // z: waypoint.position.z,
                duration: 1,
                ease: Power2.easeInOut
            })
            gsap.to(this.person.model.scale, {
                x: 4,
                y: 4,
                z: 4,
                duration: 1,
                ease: Power2.easeInOut
            })
        }
    }

    checkType() {
        if (this.sizes.width < 968) {
            this.sizes.device = "mobile";

            document.querySelector('.cursor').style.display = 'none';
        } else {
            this.sizes.device = "desktop";
        }

        // console.log(this.sizes.device);
        this.changeModelPositionMobile(this.sizes.device);
    }

    animateCursor() {
        document.addEventListener('mousemove', function (e) {
            var cursor = document.querySelector('.cursor');
            gsap.to(cursor, { duration: 0.3, left: e.pageX, top: e.pageY });
        });

        const handleMouseEnter = () => {
            gsap.to('.cursor', { duration: 0.3, scale: 1.7, background: 'transparent', borderColor: '#424656' });
        };

        const handleMouseLeave = () => {
            gsap.to('.cursor', { duration: 0.3, scale: 1, background: 'var(--cursor-background)', borderColor: 'var(--cursor-background)' });
        };

        this.overlayButton.addEventListener('mouseenter', handleMouseEnter);
        this.contactButton.addEventListener('mouseenter', handleMouseEnter);
        this.toggleButton.addEventListener('mouseenter', handleMouseEnter);
        this.swiperButtonNext.addEventListener('mouseenter', handleMouseEnter);
        this.swiperButtonPrev.addEventListener('mouseenter', handleMouseEnter);

        this.overlayButton.addEventListener('mouseleave', handleMouseLeave);
        this.contactButton.addEventListener('mouseleave', handleMouseLeave);
        this.toggleButton.addEventListener('mouseleave', handleMouseLeave);
        this.swiperButtonNext.addEventListener('mouseleave', handleMouseLeave);
        this.swiperButtonPrev.addEventListener('mouseleave', handleMouseLeave);

        this.sourceButtons.forEach(item => {
            item.addEventListener('mouseenter', handleMouseEnter);
            item.addEventListener('mouseleave', handleMouseLeave);
        })
        this.socialButtons.forEach(item => {
            item.addEventListener('mouseenter', handleMouseEnter);
            item.addEventListener('mouseleave', handleMouseLeave);
        })
    }

    resize() {
        // if (this.scroll)
        //     this.scroll.resize()

        if (this.sections)
            this.sections.resize()
    }

    update() {
        // if (this.scroll)
        //     this.scroll.update()
    }
}