import * as THREE from 'three'
import Experience from '../Experience.js'
import { gsap, Power2 } from 'gsap'

export default class Person {
    constructor() {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.time = this.experience.time
        this.debug = this.experience.debug
        this.sizes = this.experience.sizes

        // Debug
        if (this.debug.active) {
            this.debugFolder = this.debug.ui.addFolder('person')
        }

        // Resource
        this.resource = this.resources.items.personModel;

        // controls for animation
        this.lerp = {
            current: 0,
            target: 0,
            ease: 0.1,
        }

        this.setModel();
        this.setAnimation();
        this.onMouseMove();
    }

    setModel() {
        this.model = this.resource.scene;
        this.model.scale.set(4, 4, 4);
        this.model.position.y -= 3.2;
        this.model.position.x = 3.3;
        this.model.rotation.y = Math.PI * 1.9;
        this.scene.add(this.model);
        // console.log(this.model.children[1]);
        // this.model.children[1].visible = false;

        // this.model.children.forEach(element => {
        //     if(element.name === 'table') {
        //         element.position.x = 0.6268012523651123 - 2;
        //     } else if (element.name === 'chair') {
        //         element.position.x = 0.11210830509662628 - 2;
        //     } else if (element.name === 'laptop') {
        //         element.position.x = 0.004647780675441027 - 2;
        //     }
        // });

        // console.log(this.model);
    }

    setAnimation() {
        this.animation = {}

        // Mixer
        this.animation.mixer = new THREE.AnimationMixer(this.model)

        // Actions
        this.animation.actions = {}

        // this.animation.actions.idle = this.animation.mixer.clipAction(this.resource.animations[7])
        // this.animation.actions.walking = this.animation.mixer.clipAction(this.resource.animations[1])
        // this.animation.actions.running = this.animation.mixer.clipAction(this.resource.animations[2])
        // this.animation.actions.four = this.animation.mixer.clipAction(this.resource.animations[4])

        console.log(this.resource.animations);
        this.animation.actions.walking = this.animation.mixer.clipAction(
            this.resource.animations[1]
        );
        this.animation.actions.running = this.animation.mixer.clipAction(
            this.resource.animations[2]
        );
        this.animation.actions.offensive_idle = this.animation.mixer.clipAction(
            this.resource.animations[3]
        );
        this.animation.actions.jumping = this.animation.mixer.clipAction(
            this.resource.animations[4]
        );
        this.animation.actions.sitting = this.animation.mixer.clipAction(
            this.resource.animations[5]
        );
        this.animation.actions.victory = this.animation.mixer.clipAction(
            this.resource.animations[6]
        );
        this.animation.actions.typing = this.animation.mixer.clipAction(
            this.resource.animations[7]
        );
        this.animation.actions.briefcase_idle = this.animation.mixer.clipAction(
            this.resource.animations[8]
        );
        this.animation.actions.jumping_jacks = this.animation.mixer.clipAction(
            this.resource.animations[9]
        );
        this.animation.actions.breathing_idle = this.animation.mixer.clipAction(
            this.resource.animations[10]
        );
        this.animation.actions.hipHopDancing = this.animation.mixer.clipAction(
            this.resource.animations[11]
        );
        this.animation.actions.happy_hand_gesture = this.animation.mixer.clipAction(
            this.resource.animations[12]
        );

        this.animation.actions.current = this.animation.actions.briefcase_idle;
        this.animation.actions.current.play();

        // Play the action
        this.animation.play = (name) => {
            const newAction = this.animation.actions[name];
            const oldAction = this.animation.actions.current;

            newAction.reset();
            newAction.play();
            newAction.crossFadeFrom(oldAction, 1);

            this.animation.actions.current = newAction;
        };

        // Debug
        if (this.debug.active) {
            const debugObject = {
                // playIdle: () => { this.animation.play('idle') },
                playWalking: () => { this.animation.play('walking') },
                playRunning: () => { this.animation.play('running') }
            }
            // this.debugFolder.add(debugObject, 'playIdle')
            this.debugFolder.add(debugObject, 'playWalking')
            this.debugFolder.add(debugObject, 'playRunning')
        }
    }

    onMouseMove() {
        window.addEventListener("mousemove", (e) => {
            this.rotation = ((e.clientX - window.innerWidth / 2) * 2) / window.innerWidth;
            this.lerp.target = this.rotation * 0.1;
        })
    }

    resize() {
    }

    update() {

        // gsap utils lerp function
        this.lerp.current = gsap.utils.interpolate(
            this.lerp.current,
            this.lerp.target,
            this.lerp.ease
        )

        this.model.rotation.y = this.lerp.current;

        this.animation.mixer.update(this.time.delta * 0.001);
    }
}