import Experience from '../Experience'
import { gsap, Power2 } from 'gsap'

export default class Menu {
    iconMenu = document.getElementById('menu-button');
    sectionsList = document.querySelectorAll('.section');
    menuRight = document.querySelector('.nav-menu__right');
    menuButtonBar0 = document.getElementById('menu-button-bar-0');
    menuButtonBar1 = document.getElementById('menu-button-bar-1');
    menuButtonBar2 = document.getElementById('menu-button-bar-2');
    back = document.querySelector('.back');
    
    scrollAnimationDuration = .7

    isOpen = false

    constructor() {
        this.experience = new Experience();
        this.camera = this.experience.camera;
        this.person = this.experience.world.person;
        this.sections = this.experience.ui.sections;
        this.sizes = this.experience.sizes;

        this.checkClickIconMenu();
        this.checkClickMenuRight();
    }

    checkClickIconMenu() {
        this.iconMenu.addEventListener('click', () => {
            this.animateMenu();
        });
    }

    animateMenu(index) {
        console.log(this.person);
        const queryParams = new URLSearchParams(window.location.search).get('section');
        if (!this.isOpen) {
            this.isOpen = true
            gsap.to(this.person.model.position, {
                x: -3,
                duration: 2,
                ease: Power2.easeInOut
            })

            gsap.to(this.person.model.rotation, {
                y: Math.PI * 2.5,
                duration: 2,
                ease: Power2.easeInOut
            })

            if(index == 3) {
                gsap.to(this.person.model.children.find(x => x.name === 'chair').position, {
                    x: -1,
                    duration: 2,
                    ease: Power2.easeInOut
                });
            }

            // this.person.animation.play('hipHopDancing');
            this.person.animation.play('jumping_jacks');

            this.sectionsList.forEach(item => {
                item.classList.remove('slideOutLeftReverse');
                item.classList.add('slideOutLeft');
            })

            this.menuRight.classList.remove('slideInRightReverse');
            this.menuRight.classList.add('slideInRight');
            this.menuButtonBar0.classList.add('menuButtonBar0');
            this.menuButtonBar1.classList.add('menuButtonBar1');
            this.menuButtonBar2.classList.add('menuButtonBar2');
            this.back.classList.remove('slideOutLeftReverse');
            this.back.classList.add('slideOutLeft');
        } else {
            this.isOpen = false;

            let positionX = 3.3;
            if(this.sizes.device === 'mobile') {
                positionX = 1;
            }
            gsap.to(this.person.model.position, {
                x: positionX,
                duration: 2,
                ease: Power2.easeInOut
            })

            gsap.to(this.person.model.rotation, {
                y: Math.PI * 1.9,
                duration: 2,
                ease: Power2.easeInOut
            })

            this.sectionsList.forEach(item => {
                item.classList.remove('slideOutLeft');
                item.classList.add('slideOutLeftReverse');
            })

            if(index == 3) {
                this.sections.animateWorkSection();

                this.person.animation.play('typing');  
            } else {
                // this.person.animation.play('walking');
                // this.person.animation.play('briefcase_idle');
                this.sections.playCharacterAnimation(index);
            }

            this.menuRight.classList.remove('slideInRight');
            this.menuRight.classList.add('slideInRightReverse');
            this.menuButtonBar0.classList.remove('menuButtonBar0');
            this.menuButtonBar1.classList.remove('menuButtonBar1');
            this.menuButtonBar2.classList.remove('menuButtonBar2');
            this.back.classList.remove('slideOutLeft');
            this.back.classList.add('slideOutLeftReverse');
        }
    }

    checkClickMenuRight() {
        this.menuRight.addEventListener('click', (event) => {
            if (event.target.tagName === 'LI') {
                let index = event.target.dataset.index;
                this.sections.sectionQueryIndex = index;
                this.sections.goToSection();
                this.sections.updateQueryParams(index);

                this.isOpen = true;
                this.animateMenu(index);
            }
        });
    }
      
}