import EventEmitter from './EventEmitter.js'

export default class Sizes extends EventEmitter
{
    constructor()
    {
        super()

        // Setup
        this.width = window.innerWidth
        this.height = window.innerHeight
        this.pixelRatio = Math.min(window.devicePixelRatio, 2)

        // Resize event
        window.addEventListener('resize', () =>
        {
            this.width = window.innerWidth
            this.height = window.innerHeight
            this.pixelRatio = Math.min(window.devicePixelRatio, 2)

            if (this.width < 968 && this.device !== "mobile") {
                console.log('mobile');
                this.device = "mobile";
                this.trigger('switchdevice', [this.device]);
            } else if (this.width >= 968 && this.device !== "desktop") {
                console.log('desktop');
                this.device = "desktop";
                this.trigger('switchdevice', [this.device]);
            }

            this.trigger('resize', [this.device])
        })
    }

    getAbsoluteHeight(element) {
        const styles = window.getComputedStyle(element)
        const margin = parseFloat(styles['marginTop']) + parseFloat(styles['marginBottom'])
    
        return Math.ceil(element.offsetHeight + margin)
    }

    getMarginTop(element) {
        const styles = window.getComputedStyle(element)

        return Math.ceil(parseFloat(styles['marginTop']))
    }
}