import Experience from '../Experience'
import { gsap, Power2 } from 'gsap'

export default class Sections {

    scrollAnimationDuration = 2
    visible = true
    isAnimating = false
    reopeningEnabled = true

    btnContact = document.getElementById('contact-btn');
    sections = [
        {
            name: 'home-section',
            y: 0,
            container: document.getElementById('home-section'),
            offset: () => 0,
        },
        {
            name: 'about-section',
            y: 0,
            container: document.getElementById('about-section'),
            offset: () => 20,
        },
        {
            name: 'work-section',
            y: 0,
            container: document.getElementById('work-section'),
            offset: () => this.sizes.portrait ? -100 : 0,
        },
        {
            name: 'contact-section',
            y: 0,
            container: document.getElementById('contact-section'),
            offset: () => this.sizes.portrait ? -100 : 0,
        },
    ]
    scrollCount = 0;

    constructor() {
        this.experience = new Experience()
        // this.scroll = this.experience.ui.scroll
        this.gestures = this.experience.gestures
        this.sizes = this.experience.sizes
        this.person = this.experience.world.person

        this.setSectionsY()

        this.nextSection;
        this.prevSection = this.sections[0].container;

        window.addEventListener('popstate', this.handleQueryParamsChange);
        this.handleQueryParamsChange();

        this.goToSection();

        console.log('this.sectionQueryIndex', this.sectionQueryIndex);

        // console.log(this.queryParams);

        if(this.queryParams.size == 0 || this.queryParams == null) {
            this.updateQueryParams(1);
        }
        // this.updateQueryParams(1);
        
        // Scroll
        this.gestures.on('scroll-down', (value) => {
            let target = value.srcElement.id;
            // console.log('scroll-down', target);

            if(!target && value.target.offsetParent) {
                target = value.target.offsetParent.offsetParent.id;
                if(value.target.className === 'section__left') {
                    target = value.target.offsetParent.id;
                    // console.log('target', target);
                }
            }

            // console.log('srcElement', target);
            this.onScrollDown(target)
        })

        this.gestures.on('scroll-up', (value) => {
            let target = value.srcElement.id;

            // console.log('scroll-up', value);

            if(!target && value.target.offsetParent) {
                
                target = value.target.offsetParent.offsetParent.id;
                if(value.target.className === 'section__left') {
                    target = value.target.offsetParent.id;
                }

                // console.log('scroll-up', value, value.target);
            }

            this.onScrollUp(target);
        })

        this.gestures.on('touch-down', (value) => {
            let target = value.srcElement.id;
            if(!target) {
                target = value.target.offsetParent.offsetParent.id;
                if(value.target.className === 'section__left') {
                    target = value.target.offsetParent.id;
                    // console.log('target', target);
                }
            }
            this.onScrollDown(target)
        })

        this.gestures.on('touch-up', (value) => {
            // console.log('touch-up');
            let target = value.srcElement.id;

            if(!target) {
                target = value.target.offsetParent.offsetParent.id;
                if(value.target.className === 'section__left') {
                    target = value.target.offsetParent.id;
                }
            }

            this.onScrollUp(target);
        })

        this.btnContact.addEventListener('click', (e) => {
            this.updateQueryParams(4);
            this.handleQueryParamsChange();
            this.goToSection();
        })
        
        // console.log(this.sectionQueryIndex, 'sectionQueryIndex');
        this.playCharacterAnimation(this.sectionQueryIndex);
    }

    updateQueryParams(section) {
        this.queryParams.set('section', section);
        const newUrl = `${window.location.pathname}?${this.queryParams.toString()}`;
        window.history.pushState({ path: newUrl }, '', newUrl);
    }

    // Функция для обработки изменений queryparams и выполнения действий
    handleQueryParamsChange() {
        this.queryParams = new URLSearchParams(window.location.search);
        const section = this.queryParams.get('section');
        // Выполняем действия в зависимости от значения section
        this.sectionQueryIndex = Number(section);
        if(this.sectionQueryIndex == null || this.sectionQueryIndex == 0) {
            this.sectionQueryIndex = 1;
        }
        // console.log(this.sectionQueryIndex, 'sectionQueryIndex');
    }

    checkValidQuery(value) {
        return (value < this.sections.length+1) && (value > 0);
    }

    setSectionsY() {
        this.sections.forEach((section) => {
            // Reset Y
            section.y = 0

            //Get height of all sections and apply
            for (let i = 0; i < this.sections.length; i++) {
                if (i < this.sections.indexOf(section)) {
                    section.y += this.sizes.getAbsoluteHeight(this.sections[i].container)
                }
            }

            //Add sections margin-top
            section.y += this.sizes.getMarginTop(section.container)

            //Add offset
            section.y += section.offset()

            section.container.style.top = `${section.y}px`

            // console.log('section', section);
        })
    }

    goToSection() {
        if (this.sectionQueryIndex && this.checkValidQuery(this.sectionQueryIndex)) {
            this.scrollCount = this.sectionQueryIndex - 1;
            this.sections.forEach(x => {
                // console.log(x);
                x.container.style.top = '100%';
            })
            this.sections[this.scrollCount].container.style.top = '0';
            // console.log(this.scrollCount);
        }
    }

    onScrollDown(value) {
        

        if (value === this.sections[this.scrollCount].name 
            && this.scrollCount < this.sections.length - 1) {
            
            this.scrollCount++;
            this.sections.forEach(x => {
                x.container.style.top = '200%';
            })

            this.sections[this.scrollCount].container.style.top = '0';

            this.sectionQueryIndex++;
            console.log('this.sectionQueryIndex', this.sectionQueryIndex);
            this.updateQueryParams(this.sectionQueryIndex);
            this.playCharacterAnimation(this.sectionQueryIndex);      
        }
        
    }

    onScrollUp(value) {
        console.log('this.scrollCount', 
        value, 
        this.sections[this.scrollCount].name, 
        this.scrollCount,
        this.sectionQueryIndex);
        if (value === this.sections[this.scrollCount].name && this.scrollCount > 0) {
            this.scrollCount--;
            this.sections.forEach(x => {
                x.container.style.top = '100%';
            })
            this.sections[this.scrollCount].container.style.top = '0';
            this.sectionQueryIndex--
            this.updateQueryParams(this.sectionQueryIndex);
            this.playCharacterAnimation(this.sectionQueryIndex);
        }
    }

    playCharacterAnimation(scrollCount) {
        this.person.model.children.forEach(element => {
            if(element.name === 'table') {
                gsap.to(element.position, {
                    x: -2,
                    duration: 2,
                    ease: Power2.easeInOut
                });
            } else if (element.name === 'chair') {
                gsap.to(element.position, {
                    x: - 1.5,
                    duration: 2,
                    ease: Power2.easeInOut
                });
            } else if (element.name === 'laptop') {
                gsap.to(element.position, {
                    x: - 1.5,
                    duration: 2,
                    ease: Power2.easeInOut
                });
            }
        });
        
        if(scrollCount == 1) {
            this.person.animation.play('briefcase_idle');
            // this.person.animation.play('jumping_jacks');
            // this.person.animation.play('happy_hand_gesture');
        } else if(scrollCount == 2) {
            this.person.animation.play('hipHopDancing'); 
        } else if(scrollCount == 3) {
            this.animateWorkSection();
            this.person.animation.play('typing');
        } else if(scrollCount == 4) {
            // this.person.animation.play('hipHopDancing'); 
            this.person.animation.play('happy_hand_gesture');
        }
    }

    animateWorkSection() {
        this.person.model.children.forEach(element => {
            if(element.name === 'table') {
                gsap.to(element.position, {
                    x: -0.5035430788993835,
                    duration: 2,
                    ease: Power2.easeInOut
                });
            } else if (element.name === 'chair') {
                gsap.to(element.position, {
                    x: 0.112108,
                    duration: 2,
                    ease: Power2.easeInOut
                });
            } else if (element.name === 'laptop') {
                gsap.to(element.position, {
                    x: 0.004648,
                    duration: 2,
                    ease: Power2.easeInOut
                });
            }
        });
    }

    resize() {
        // this.setSectionsY()
        this.goToSection();
    }
}