export default [
    // {
    //   name: "testMatcap",
    //   type: "texture",
    //   path: "textures/matcap.png",
    // },
    {
      name: "personModel",
      type: "gltfModel",
      path: "models/Person/girl_hipster-desk3.gltf",
    },
  ];
  

// export default [
//     {
//         name: 'environmentMapTexture',
//         type: 'cubeTexture',
//         path:
//         [
//             'textures/environmentMap/px.jpg',
//             'textures/environmentMap/nx.jpg',
//             'textures/environmentMap/py.jpg',
//             'textures/environmentMap/ny.jpg',
//             'textures/environmentMap/pz.jpg',
//             'textures/environmentMap/nz.jpg'
//         ]
//     },
//     {
//         name: 'grassColorTexture',
//         type: 'texture',
//         path: 'textures/dirt/color.jpg'
//     },
//     {
//         name: 'grassNormalTexture',
//         type: 'texture',
//         path: 'textures/dirt/normal.jpg'
//     },
//     {
//         name: 'personModel',
//         type: 'gltfModel',
//         path: 'models/Person/girl_hipster.gltf'
//     }
// ]