import Experience from '../Experience.js'
import Environment from './Environment.js'
import * as THREE from 'three'
import Person from './Person.js'
import { gsap } from 'gsap'

export default class World {
    constructor() {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.gestures = this.experience.gestures
        this.environment = this.experience.environment
        this.theme = this.experience.theme

        // Wait for resources
        this.resources.on('ready', () => {
            // Setup
            // this.floor = new Floor()
            this.person = new Person()
            this.environment = new Environment()
            //Enable gestures
            this.gestures.init()

            this.person.animation.play('walking', .35)

            this.playIntro();
        })

        this.theme.on('switch', (theme) => {
            this.switchTheme(theme);
            this.addLight(theme);
        })
    }

    switchTheme(theme) {
        if (this.environment) {
            this.environment.switchTheme(theme);
        }
    }

    addLight(theme) {
        const width = 8;
        const height = 8;
        const intensity = 3;
        this.rectLight = new THREE.RectAreaLight(0xffffff, intensity, width, height);
        if (theme === 'dark') {
            this.rectLight.position.set(3, 4, 0.5);
            this.rectLight.rotation.x = -Math.PI / 2;
            this.rectLight.rotation.z = Math.PI / 4;
            this.scene.add(this.rectLight);
        } else {
            const rectAreaLight = this.scene.children.find(item => item.type === "RectAreaLight");
            this.scene.remove(rectAreaLight);
        }

    }

    playIntro() {
        this.timeline = new gsap.timeline();
        this.timeline.set(".animatedis", { y: 0, yPercent: 100 });
        this.timeline.to(".preloader", {
            display: 'none',
            delay: 1,
            onComplete: () => {
                document.querySelector(".preloader").classList.add("hidden");
            },
        });
    }

    resize() {
        if (this.person)
            this.person.resize()
    }

    update() {
        if (this.person)
            this.person.update()
    }
}