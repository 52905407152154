import Experience from '../Experience'
import Swiper from 'swiper';
// import Swiper styles
import 'swiper/css';

import { Navigation, Pagination } from 'swiper/modules';
// import Swiper and modules styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

export default class Slider {
    constructor() {
       this.initSlider();
    }

    initSlider() {
        const swiper = new Swiper('.swiper', {
            // Optional parameters
            // direction: 'vertical',
            // configure Swiper to use modules
            modules: [Navigation, Pagination],
            loop: true,
            slidesPerView: 1,
            // spaceBetween: 30,
          
            // If we need pagination
            pagination: {
              el: '.swiper-pagination',
            },
          
            // Navigation arrows
            navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            },
          });
    }
}